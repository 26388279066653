<template>
  <div class="container">
    <!-- <navigation msg="完善信息"></navigation> -->
     <div class="nac">
       <van-icon  @click="goback" size=".35rem" name="arrow-left"/>
      <div class="xxm">完善简历</div>
    </div>
    <div class="identity">
      <p class="buddha">
        <span class="sr" @click="showPopup">求职区域</span>
        <input
          readonly="readonly"
          class="lian"
          type="text"
          v-model="address"
          placeholder="请选择所在区域"
          style="text-align: right; width: 5rem"
          @click="showPopup"
        />
      </p>

      <!-- 求职区域 -->
      <van-popup v-model="show" position="bottom">
        <van-area
          :area-list="areaList"
          @confirm="onConfirm"
          @cancel="show = false"
          :columns-num="2"	
          :columns-placeholder="['请选择', '全部', '全部']"
        />
      </van-popup>
      <p class="buddha">
        <span class="sr" @click="create()">过往履历</span>
        <span class="tj" @click="create()">添加过往履历</span>
        <van-icon
          name="add-o"
          size="0.6rem"
          @click="create()"
          style="float: right"
        />
      </p>
      <p class="pp" v-if="list.length>0">往届工作履历</p>
      <div
        class="box"
        v-for="(item, index) in list"
        @click.stop="xg(item)"
        :key="index"
      >
        <div class="title">
          <span>{{ item.company_name }}</span>
        </div>
        <span class="position">工作职位：{{ item.position }}</span>
        <span class="item">{{ item.entry_time }}～{{ item.quit_time }}</span>
        <div class="btn">
        </div>
      </div>
      <p class="buddha">
        <span class="sr">职业资格证书/荣誉奖项 </span>
      </p>
      <textarea
        class="txt"
        name=""
        id=""
        cols="20"
        placeholder="请填写荣誉奖项(选填)"
        v-model="honor"
        rows="10"
        @input="honors"
      ></textarea>

      <div class="qd" @click="share">确认</div>
    </div>
  </div>
</template>

<script>
import Addressinfo from "../../util/Area";
import { Toast } from 'vant';
export default {
  data() {
    return {
      show: false,
      list: [], //过往履历
      advantage: "",
      address: "", //求职区域
      honor: "", //荣誉
      areaList: Addressinfo,
      kk: "",
    };
  },
  components:{
    // navigation
  },
  created() {
    if(sessionStorage.getItem("resumeType") == 0){
    this.address = sessionStorage.getItem("area");
      let arr =  JSON.parse(sessionStorage.getItem("work_resume"))
      // console.log(arr)
      if(arr == null){
        sessionStorage.setItem('work_resume','[]')
      }else if(arr.length > 0){
        this.list = arr
      }
    }else{
      // this.address = 
      let perResume = JSON.parse(sessionStorage.getItem("perResume"))
      this.address = perResume.province+perResume.city+perResume.district
      sessionStorage.setItem("area",perResume.province+perResume.city+perResume.district) 

      this.list = JSON.parse(sessionStorage.getItem("work_resume")) ||  perResume.work_resume
       sessionStorage.setItem("work_resume", JSON.stringify(this.list) ) 
      this.honor = perResume.honor
      sessionStorage.setItem("honor", perResume.honor) 
      // console.log(perResume)
    }
      
  },
  mounted() {
    // this.kk = this.settime(this);
  },
  methods: {
    xg(item) {
      // console.log(item)
      sessionStorage.setItem("item1",JSON.stringify(item))
      sessionStorage.setItem("type1",1)
      this.$router.push({
        name: "record",
      });
    },
    create(){
      sessionStorage.setItem("type1",0)
      this.$router.push({
        name: "record",
      });
    },
    //同步保存职业资格证书/荣誉奖项
    goback(){
      this.$router.push("./resume1")
    },
    honors() {
      sessionStorage.setItem("honor", this.honor);
    },
    //延时器
    settime(ss) {
      var aa;
      clearTimeout(aa);
      return function () {
        aa = setTimeout(() => {
          // console.log("aaa");
          ss.$router.push({
            name: "ending",
            params: {
              num: 1,
            },
          });
        }, 1000);
      };
    },
    del(data) {
      // console.log(data);
      this.$dialog
        .confirm({
          title: "警告",
          message: "是否删除本条履历",
        })
        .then(() => {
          this.list = this.list.filter((item, ) => {
            return item.id != data.id;
          });
          sessionStorage.setItem("list", JSON.stringify(this.list));
        })
        .catch(() => {
          // on cancel
        });
    },
    //保存内容进入结束页面
    share() {
      let salary = sessionStorage.getItem("salary")
      let jop = sessionStorage.getItem("jop")
      let education_background = sessionStorage.getItem("education_background")
      let sex = sessionStorage.getItem("sex")
      let area = sessionStorage.getItem("area")
      let years_working = sessionStorage.getItem("years_working")
      let email = sessionStorage.getItem("email")
      let industry ;
      industry =  (sessionStorage.getItem("industry"));
        // console.log(industry)
      let politics = sessionStorage.getItem("politics")
      let job_status = sessionStorage.getItem("job_status")
      let work_resume = JSON.parse(sessionStorage.getItem("work_resume"))
      if(work_resume.length == 0){
        work_resume = ''
      }
      // console.log(work_resume)
      let educational_experience = JSON.parse(sessionStorage.getItem("educational_experience"))
      // console.log(educational_experience)
      let household_register = sessionStorage.getItem("hj")
      let honor = sessionStorage.getItem("honor")
      // console.log(salary,jop,education_background,sex,area,years_working,politics,job_status,work_resume,educational_experience,household_register,honor)
      this.$http.post("/user/v1/personal_info/perfectResume",{
        reqType:"perfectResume",
        salary:salary,
        jop:jop,
        education_background:education_background,
        sex:sex,
        area:area,
        years_working:years_working,
        industry:industry,
        politics:politics,
        job_status:job_status,
        work_resume:work_resume,
        educational_experience:educational_experience,
        household_register:household_register,
        honor:honor,
        email:email
      })
      .then((res)=>{
        res = JSON.parse(res.data)
        // console.log(res)
        if(res.code == 0){
          sessionStorage.clear()
          Toast.success("保存成功")
          setTimeout(()=>{
            this.$router.push("./userResume")
          },1000)
        }else{
          Toast.fail(res.msg)
        }
      })
      .catch((e)=>{console.log(e)})
    },
  
    //确定选择求职区域
    onConfirm(values) {
      this.address = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join(" ");
      this.show = false;
      // console.log(this.address)
      // this.address = this.address.split("-");
      sessionStorage.setItem("area", this.address);
    },
    //打开求职区域插件
    showPopup() {
      this.show = true;
    },
  },
};
</script>

<style scoped >
.xxm {
    position: absolute;
    margin-left: 50%;
    transform: translateX(-50%);
}

.nac > :first-child {
    margin-left: 0.32rem;
}

.nac {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.48rem;
  padding: 0.3rem 0;
  position: relative;
  border-bottom-color: #ebedf0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
 
.container{
  position: fixed;
  height:100%;
  width:100%;
  background-color: #fff;
  top: 0;
  bottom:0;
  overflow-y:scroll;
  overflow-x:hidden;
}
.msg {
  height: 0.88rem;
  line-height: 0.88rem;
  line-height: 0.48rem;
  border-bottom: solid 0.01rem #eeeeee;
}
.tj {
  margin-left: 3rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #c4c4c4;
}
.pp {
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin: 0.6rem 0;
}
.btn {
  display: flex;
  margin-left: 4rem;
  margin-bottom: 0.41rem;
 
}
.btn button {
    width: 2rem; 
     height: 0.57rem;
    margin-left: 0.18rem;
    margin-top:0.7rem;
    border: 0.02rem solid #f55d10;
    border-radius: 0.1rem;
    color: #f55d10;
    background: #ffe6d9;
    font-size: 0.24rem;
    font-family: PingFangSC-Medium, PingFang SC;
  }
.zz {
  border-bottom: 0 none !important;
}
.txt {
  padding:0.2rem;
  width: 94%;
  height: 2rem;
  font-size: 0.26rem;
  resize: none;
  border:0.01rem solid #ddd;
}
.ss::before {
  content: "*";
  color: #ff0000;
  font-size: 0.25rem;
  position: relative;
  margin-right: 0.1rem;
  /* top: 0.1rem; */
}
.item {
  float: right;
 
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  right: 0;
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ccc;
}
.qd {
  width: 6.86rem;
  height: 0.96rem;
  line-height: 0.96rem;
  text-align: center;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  background: linear-gradient(90deg, #f55613 0%, #fc9e46 100%);
  border-radius: 0.48rem;
  margin-top:1rem;
}
.yl {
  margin-left: 1rem;
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
}
.box {
  border-bottom: 0.01rem solid #ccc;
  margin-bottom: 0.5rem;
  padding-bottom:0.3rem;
}
.box .p {
    
    font-size: 0.3rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    word-break: break-all;
    display: -webkit-box; /**对象作为伸缩盒子模型展示**/
    -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
    -webkit-line-clamp: 3; /**显示的行数**/
    overflow: hidden; /**隐藏超出的内容**/
  }
.box .title {
    display: flex;
    justify-content: space-between;
    font-size: 0.34rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #222222;
  }
.box textarea {
    resize: none;
    
    width: 100%;
    height: 1.6rem;
    font-size: 0.3rem;
    border: 0 none;
  }
.box textarea:disabled {
    background-color: #fff;
  }
  .box  .position {
    float: left;
    font-size: .29rem;
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #575656;
  }
.msg .wan {
  margin-left: 2.8rem;
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
}
.identity {
  margin: 0rem 0.32rem;
}

.identity .buddha {
  display: flex;
  align-items: center;
  justify-content: space-between;
 
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #697386;
  line-height: 1.2rem;
}

.identity .buddha .lian {
  border: none;
}
</style>